<template>
    <div class="page-view">
       <b-container class="post-preview">
            <b-row>
                <b-col lg="6">
                    <b-card-img-lazy :src="api + post.thumbnail.url" alt="Image" class="rounded-0" align="left"></b-card-img-lazy>
                </b-col>
                <b-col lg="6">
                    <h1 class="post-title" style="text-align:left;">{{post.title}}</h1>
                    <p class="post-excerpt">{{post.excerpt}}</p>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="post-textbody" style="">
            <p v-html="post.textbody"></p>
        </b-container>
            <div
                id="picture-container" 
                v-viewer="{
                    movable: false,
                    navbar: false,
                    title: false,
                    movable: false, 
                    zoomable: false,
                    view: this.onView,
                    transition: false,
                    toolbar: {
                        zoomIn: 0,
                        zoomOut: 0,
                        oneToOne: 0,
                        reset: 0,
                        prev: 1,
                        play: {
                        show: 0,
                        size: 'large',
                        },
                        next: 1,
                        rotateLeft: 0,
                        rotateRight: 0,
                        flipHorizontal: 0,
                        flipVertical: 0,
                    }
                }">
                <b-container class="post-gallery" style="">
                    <div class="img-container" v-for="picture in post.gallery" :key="picture.id">
                        <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" style="max-width: 80%"></b-card-img-lazy>
                    </div>
                </b-container>
            </div>
    </div>
</template>

<script>

export default {
        name: 'PostDetails',
        data () {
            return {
                post: [],
                id: this.$route.params.id,
                error: null,
                api: process.env.VUE_APP_ALTERED_ROOT_API,
                headers: {'Content-Type': 'application/json'}
            }
        },
        methods: {
            parseJSON: function (resp) {
                return (resp.json ? resp.json() : resp);
            },
                checkStatus: function (resp) {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp;
            }
                return this.parseJSON(resp).then((resp) => {
                    throw resp;
                });
            },
            onView: function (){
                var element = document.getElementById('picture-container');
                element.scrollTop = element.scrollHeight;
            }
        },
        async mounted () {
            window.scrollTo(0, 0)
            try {
            const response = await fetch(this.api +"/posts/"+ this.id, {
                method: 'GET',
                headers: this.headers,
            }).then(this.checkStatus)
                .then(this.parseJSON);
            this.post = response
            } catch (error) {
              this.error = error
            }
        }
    }

</script>

<style scoped>


.page-view .post-preview{
    min-height: 70vh;
    padding-top:25vh;
}

.post-textbody{
    margin: 5vh auto 15vh auto;
    font-size: 1.5em;
}

.gallery-img{
    margin: 1em 0 1em 0;
    transition: opacity 3s ease-in-out;

}
.post-title{
    font-weight: 800;
}

@media screen and (min-width: 800px) {
    .post-textbody{
        width:55vw;
    }
    .container{
        max-width:70vw;
    }
    .img-container{
        max-width: 1140px;
        margin: 0 auto 0 auto;
    }
}

@media screen and (min-width: 700px) and (max-width:799px) {

}

@media screen and (max-width: 699px) {
    .post-textbody{
        margin: 10vh auto 0 auto;
        font-size: 1em;
    }
}
</style>
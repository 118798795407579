var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-view"},[_c('b-container',{staticClass:"post-preview"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-card-img-lazy',{staticClass:"rounded-0",attrs:{"src":_vm.api + _vm.post.thumbnail.url,"alt":"Image","align":"left"}})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('h1',{staticClass:"post-title",staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.post.title))]),_c('p',{staticClass:"post-excerpt"},[_vm._v(_vm._s(_vm.post.excerpt))])])],1)],1),_c('b-container',{staticClass:"post-textbody"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.post.textbody)}})]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
                movable: false,
                navbar: false,
                title: false,
                movable: false, 
                zoomable: false,
                view: this.onView,
                transition: false,
                toolbar: {
                    zoomIn: 0,
                    zoomOut: 0,
                    oneToOne: 0,
                    reset: 0,
                    prev: 1,
                    play: {
                    show: 0,
                    size: 'large',
                    },
                    next: 1,
                    rotateLeft: 0,
                    rotateRight: 0,
                    flipHorizontal: 0,
                    flipVertical: 0,
                }
            }),expression:"{\n                movable: false,\n                navbar: false,\n                title: false,\n                movable: false, \n                zoomable: false,\n                view: this.onView,\n                transition: false,\n                toolbar: {\n                    zoomIn: 0,\n                    zoomOut: 0,\n                    oneToOne: 0,\n                    reset: 0,\n                    prev: 1,\n                    play: {\n                    show: 0,\n                    size: 'large',\n                    },\n                    next: 1,\n                    rotateLeft: 0,\n                    rotateRight: 0,\n                    flipHorizontal: 0,\n                    flipVertical: 0,\n                }\n            }"}],attrs:{"id":"picture-container"}},[_c('b-container',{staticClass:"post-gallery"},_vm._l((_vm.post.gallery),function(picture){return _c('div',{key:picture.id,staticClass:"img-container"},[_c('b-card-img-lazy',{staticClass:"rounded-0 gallery-img",staticStyle:{"max-width":"80%"},attrs:{"src":_vm.api + picture.formats.large.url,"alt":"Image","offset":"0"}})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }